<template>
  <div class="mediumData">
    <div></div>
    <div class="tableT">
      <el-table style="margin-top: 20px;min-width:1100px" :data="tableData" v-if="istrue">
        <el-table-column prop="title" label="文件名称" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button
              @click.native.prevent="deleteRow(scope.row)"
              type="text"
              size="small"
            >
              下载
            </el-button> -->
            <el-button
              @click.native.prevent="CKclick(scope.row)"
              type="text"
              size="small"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin: 10px 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="config.pageNum"
          :page-size="config.pageSize"
          :total="total"
        >
        </el-pagination>
      </div>

      <div v-if="!istrue">
        <div @click="fanhui" style="margin: 20px 0">
          <i class="el-icon-back" style="font-size: 30px"></i>
        </div>
        <div v-html="contet"></div>
      </div>
      <div v-if="ispublicityvideo">
        <el-dialog
          title="预览"
          :visible.sync="ispublicityvideo"
          width="650px"
          center
        >
          <video
            width="600"
            height="400"
            controls
            autoplay="autoplay"
            id="videoPlayer"
          >
            <source :src="publicityvideo" type="video/mp4" />
          </video>
          <span slot="footer" class="dialog-footer">
            <el-button @click="ispublicityvideoClick">关 闭</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <div class="tipBox">
      <div>
      <div class="pointBox" v-if="islogin">
        <el-card class="box-card">
          <div>您还未登录，可点击按钮前往登录</div>
          <el-button type="primary" size="small" style="margin-top: 10px;width: 100%;" @click="goLogin()">前往登录</el-button>
        </el-card>
      </div>
    </div>
    <div>
      <div class="crardBox">
        <el-card class="box-card">
          <div class="title">中长协服务热线：</div>
          <div style="margin-top: 20px">联系人:孙启宸</div>
          <div>电话:13779181117</div>
          <div style="margin-top: 10px">公司邮箱号</div>
          <div>xjmjzx@126.com</div>
        </el-card>
      </div>
    </div>
    </div>
   
  </div>
</template>

<script>
import aa from "./aa.json";
const { baseUrl } = require("@/config");
import { queryList } from "@/api/Medium.js";
export default {
  data() {
    return {
      islogin:true,
      ispublicityvideo: false,
      istrue: true,
      contet: null,
      tableData: [],
      // baseUrl: baseUrl,
      baseUrl: "https://testapi.xjcec.com/",
      publicityvideo: "",
      isaccount: false,
      pdfSrc: "", // pdf文件地址
      filename: "",
      total: 0,
      config: {
        articleType: "191",
        pageNum: 1,
        pageSize: 12,
      },
    };
  },
  mounted() {
    // this.tableData = aa.page.dataRows;
    this.getlist();
  },

  methods: {
    getlist() {
      let token = window.localStorage.getItem("token");
      if(token){
        this.islogin=false
      }else{
        this.islogin=true
      }
      queryList(this.config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.config.pageNum = 12;
      this.config.pageSize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.config.pageNum = val;
      this.getlist();
    },
    ispublicityvideoClick() {
      this.publicityvideo = null;
      this.ispublicityvideo = false;
    },
    examinecontract(item) {
      let url = this.baseUrl + "third/third/ossGetFile/" + item;
      window.open(url);
    },
    CKclick(row) {
      this.$router.push({
        path: "/medidetale",
        query: {
          id: row.articleId,
        },
      });
      // if (row.src.slice(-4) == ".pdf") {
      //   this.examinecontract(row.src);
      // } else if (row.src.slice(-4) == ".mp4") {
      //   this.ispublicityvideo = true;
      //   this.publicityvideo = this.baseUrl + "third/third/fileProxy/" + row.src;
      // } else {
      //   let base =
      //     "http://www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=";
      //   // fileUrl自己word文档存储的线上地址
      //   let fileUrl = this.baseUrl + "third/third/ossGetFile/" + row.src;
      //   let url = encodeURIComponent(fileUrl);
      //   window.open(base + url, "_blank");
      // }
      // this.contet = row.content.text;
      // this.istrue = !this.istrue;
      // console.log(this.contet);
    },
    fileLinkToStreamDownload(url) {
      let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    getDay() {
      return `${this.filename}.pdf`;
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    fanhui() {
      this.istrue = true;
    },
    // stringToHTML(str) {
    //   var dom = document.createElement("div");
    //   dom.innerHTML = str;
    //   return dom;
    // },
    deleteRow(row) {
      console.log(row);
      let newurl = this.baseUrl + "third/third/ossGetFile4/" + row.id;
      if (row.src.slice(-4) == ".pdf") {
        this.filename = row.title;
        this.fileLinkToStreamDownload(newurl);
      } else if (row.src.slice(-4) == ".mp4") {
        window.open(newurl, "_blank");
      } else {
        console.log(row, newurl);
        const a = document.createElement("a");
        // 给a标签的href属性值加上地址,注意:这里是绝对路径,不用加 点.
        a.href = newurl;
        // 设置下载文件文件名,这里加上.xlsx指定文件类型,pdf文件就指定.fpd即可
        a.download = row.title;
        // 障眼法藏起来a标签
        a.style.display = "none";
        // 将a标签追加到文档对象中
        document.body.appendChild(a);
        // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
        a.click();
        // 一次性的,用完就删除a标签
        a.remove();
      }
    },
    downloadVideo(url, name) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      return this.$message.success("下载成功");
    },
    //前往登录
    goLogin(){
    this.$router.push({
      path:'/login'
    })
    }
  },
};
</script>

<style lang="scss" scoped>
.mediumData {
  width:1200px;
  height: 100%;
  // max-width: 1200px;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  // margin-left: 100px;
  .tableT {
    max-width: 1200px;
  }
}
.tipBox{
  display: flex;
  flex-direction:column;
}
.pointBox{
  margin-top: 50px;
  margin-left: 10px;
}
.crardBox {
  margin-top: 100px;
  margin-left: 10px;
}
.title {
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
}
.text {
  font-size: 14px;
}
.item {
  padding: 18px 0;
}
.box-card {
  width: 180px;
}
</style>